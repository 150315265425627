import BigNumber from 'bignumber.js';

/**
 * Calculates the unit price based on the given price, tax percentage, and discount.
 *
 * @param price - The original price of the item.
 * @param taxPercentage - The tax percentage applied to the item.
 * @param discount - The discount applied to the item.
 * @returns The calculated unit price.
 */
export const calculateUnitPrice = (price: number, taxPercentage: number, discount: number, roundingDecimals = 2): {
    unitPrice: number,
    formattedUnitPrice: string,
} => {
    // Ensure inputs are valid numbers
    const validPrice = new BigNumber(price ?? 0);
    const validTaxPercentage = new BigNumber(taxPercentage ?? 0).dividedBy(100);
    const validDiscount = new BigNumber(discount ?? 0);

    // Calculate the tax rate
    const taxRate = validTaxPercentage.plus(1);

    // Calculate the unit price
    const unitPrice = validPrice.dividedBy(taxRate).minus(validDiscount);

    return {
        unitPrice: unitPrice.toNumber(),
        formattedUnitPrice: unitPrice.toFormat(roundingDecimals),
    };
};

export const calculateTaxAmount = (unitPrice: number, taxPercentage: number, quantity: number, roundingDecimals = 2): {
    taxAmount: number,
    formattedTaxAmount: string,
} => {
    // Ensure inputs are valid numbers
    const validUnitPrice = new BigNumber(unitPrice ?? 0);
    const validTaxPercentage = new BigNumber(taxPercentage ?? 0).dividedBy(100);
    const validQuantity = new BigNumber(quantity ?? 0);

    // Calculate the tax amount
    const taxAmount = validUnitPrice.times(validTaxPercentage).times(validQuantity);

    return {
        taxAmount: taxAmount.toNumber(),
        formattedTaxAmount: taxAmount.toFormat(roundingDecimals),
    };
};

export const itemCodeGenerator = (description: string, unit_name: string) => {
    // const code = `${description.substring(0, 3).toUpperCase()}-${unit_name.substring(0, 3).toUpperCase()}-${Math.floor(Math.random() * 1000)}`;
    const descriptionParts = description.split(' ');
    const codeParts = descriptionParts.map((part) => part.substring(0, 3).toUpperCase());
    const code = `${codeParts.join('-')}-${unit_name.substring(0, 3).toUpperCase()}-${Math.floor(Math.random() * 1000)}`;
    return code;
};
