import React, { useState } from 'react';
import { debounce } from 'lodash';
import { Typography, AutocompleteSearch } from '@notch-ordering/ui-components';
import { useGetCustomers } from '@/ar/hooks/queries/CustomerQueries.hook';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { GetCustomerResponse } from '@/ar/network/AccountsReceivable.network';

const formatAddress = (address: GetCustomerResponse['address'] = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: ''
}): string => {
    const { line1, line2, city, state, postal_code: postalCode } = address;
    return [line1, line2, city, state, postalCode].filter((v) => !!v).join(', ') || '-';
};

export type CustomerOption = {
    name: GetCustomerResponse['name'],
    address: GetCustomerResponse['address'],
    id: GetCustomerResponse['id'],
    external_id?: GetCustomerResponse['external_id'],
};

export const createNewCustomerOption = {
    name: 'Create New Customer',
    address: {},
    id: 'new-customer',
};

interface CustomerSearchProps {
    onSelect: (option: CustomerOption) => void,
    autoFocus?: boolean,
}

const CustomerSearch: React.FC<CustomerSearchProps> = ({ onSelect, autoFocus }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
    const { supplierLoginData, supplier } = useSupplierStore();

    // Debounce the search query
    const debouncedSearch = debounce((query) => {
        setDebouncedSearchQuery(query);
    }, 300);

    // Update the debounced query whenever the search query changes
    React.useEffect(() => {
        debouncedSearch(searchQuery);

        // Cleanup the debounce on unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery]);

    const { data, isLoading } = useGetCustomers({
        supplierID: supplierLoginData?.supplier_id,
        ...debouncedSearchQuery && { name: debouncedSearchQuery },
        limit: 10,
    });

    const options = [
        ...(data?.customers?.map(
            (customer) => ({
                name: customer.name,
                address: customer.address,
                id: customer.id,
                external_id: customer.external_id,
            })
        ) || []),
        supplier?.allow_edit_customer && createNewCustomerOption
    ];

    const optionRenderer = (option: CustomerOption) => {
        if (option.id === createNewCustomerOption.id) {
            return (
                <div className="flex flex-col self-start pl-2 w-full" onClick={() => onSelect(option)}>
                    <Typography variant="LG-1" className="mb-0">{option.name}</Typography>
                </div>
            );
        }

        const formattedAddress = formatAddress(option.address);

        return (
            <div className="flex flex-col self-start pl-2 w-full py-1">
                <Typography variant="LG-1" className="mb-0 flex justify-between gap-4 pr-3"><span>{option.name}</span>
                    {!!option?.external_id && <span>{option?.external_id}</span>}
                </Typography>
                {formattedAddress && (<Typography variant="BASE" className="text-gray-600 mb-0">
                    {formattedAddress}
                </Typography>)}
            </div>
        );
    };

    const handleSearchQueryChange = (value: string) => {
        if (value === createNewCustomerOption.name) {
            setSearchQuery('');
            return;
        }
        setSearchQuery(value);
    };

    return (
        <AutocompleteSearch
            searchQuery={searchQuery}
            loading={isLoading}
            loadingText={debouncedSearchQuery ? `Searching customers "${debouncedSearchQuery}" ... ` : 'Loading...'}
            options={options}
            noOptionsText="No customers found"
            customFilterOptions={(x) => x}
            optionRenderer={optionRenderer}
            onInputChanged={handleSearchQueryChange}
            getOptionLabel={(option: CustomerOption) => option.name}
            autoFocus={autoFocus || false}
            openOnFocus={true}
            variant="SMALL"
            multiple={false}
            // hack to disable clearable
            disableClearable={true as false}
            onOptionSelect={onSelect}/>
    );
};

export { CustomerSearch };
