import { mergeClassNames, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import FileAttachmentIcon from '@icons/file-attachment-icon.svg';
import { SectionTitle } from './SectionTitle';
import { SeparatorInternalPageFullWidth } from './SeparatorInternalPageFullWidth';

export interface InvoiceAttachmentsProps {
    attachments: string[],
    showTitle?: boolean,
    showSeparator?: boolean,
    separatorClassName?: string,
}

export const InvoiceAttachments : React.FC<InvoiceAttachmentsProps> = ({ attachments, showTitle, showSeparator, separatorClassName = '' }) => {
    if (!attachments.length) return null;
    return <>
        {showSeparator && <SeparatorInternalPageFullWidth className="pb-px" separatorClassName={mergeClassNames('-ml-10 w-[calc(100%+40px)]', separatorClassName)} />}
        {showTitle && <SectionTitle className=" pt-6 mb-6">Attachments</SectionTitle>}
        <div className="space-y-2">
            {attachments.map((attachment, idx) => (
                <Typography as="div" key={idx} className="m-0 flex gap-2 items-center">
                    <a href={attachment} target="_blank" rel="noreferrer" className="text-gray-700 hover:text-teal-500 flex gap-2 items-center">
                        <FileAttachmentIcon className="w-4 h-4" />
                        {attachment.split('/').pop().split('?')[0]}
                    </a>
                </Typography>
            ))}
        </div>
    </>;
};
