import create from 'zustand';
import { ApBillItem, GetTransactionItem, Transaction } from '../network/AccountsReceivable.network';

export enum ApBillModals {
    LineItem = 'lineItemsModal',
    DeleteItem = 'deleteItemModal',
    DuplicateBillNumberConfirmModal = 'duplicateBillNumberConfirmModal',
    EditBillConfirmationModal = 'editBillConfirmationModal',
}

export type ApBillStoreState = {
    modals: Partial<Record<ApBillModals, boolean>> & {
        selectedItem?: ApBillItem,
        bill?: Transaction,
    },

};

type ApBillStoreActions = {
    setModal: (modal: ApBillModals, show: boolean) => void,
    openModal: (modalType: ApBillModals, lineItem?: GetTransactionItem, bill?: Transaction) => void,
    closeModal: (modalType: ApBillModals) => void, };

export type ApBillStore = ApBillStoreState & ApBillStoreActions;

const AP_BILL_STORE_INITIAL_STATE: ApBillStoreState = {
    modals: {
        selectedItem: null,
        bill: null,
        [ApBillModals.LineItem]: false,
        [ApBillModals.DeleteItem]: false,
        [ApBillModals.DuplicateBillNumberConfirmModal]: false,
        [ApBillModals.EditBillConfirmationModal]: false,
    },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 * Defines the draft bill store with initial state and modal management functions this is intended for draft bill details page.
 *
 * @param set - Function to update the state.
 * @param getState - Function to get the current state.
 * @returns The draft bill store with initial state and modal management functions.
 * @property {function(ApBillModals, boolean): void} setModal - Function to set the visibility of a modal.
 * @param modal - The modal to be shown or hidden.
 * @param show - Boolean indicating whether to show or hide the modal.
 * @property {function(ApBillModals, GetTransactionItem, Transaction): void} openModal - Function to open a modal and set the selected item.
 * @param modalType - The type of modal to open.
 * @param lineItem - The line item to be selected.
 * @param bill - The bill (arTransaction).
 * @property {function(ApBillModals): void} closeModal - Function to close a modal and clear the selected item.
 * @param modalType - The type of modal to close.
 */

export const apBillStoreDefinition = (set): ApBillStore => ({
    ...AP_BILL_STORE_INITIAL_STATE,
    setModal: (modal: ApBillModals, show: boolean) => set((state) => ({
        modals: {
            ...state.modals,
            [modal]: show,
        },
    })),
    openModal: (modalType: ApBillModals, lineItem?: GetTransactionItem, bill?: Transaction) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: lineItem,
                bill,
                [modalType]: true,
            },
        }));
    },
    closeModal: (modalType: ApBillModals) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: null,
                bill: null,
                [modalType]: false,
            },
        }));
    },

});

export const useApBillStore = create<ApBillStore>(apBillStoreDefinition);
