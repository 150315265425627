import { z } from 'zod';

const emailSchema = z.object({
    email: z.string().email({ message: 'Please provide a valid email address.' }).optional(),
});

const additionalEmailSchemaValidation = z.array(emailSchema).optional().nullish();

export const addressSchema = z.object({
    line1: z.string().optional(),
    line2: z.string().optional(),
    city: z.string().optional(),
    postal_code: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
});

export const validationSchema = z.object({
    terms: z.coerce.number().min(0),
    ccEmails: additionalEmailSchemaValidation,
    name: z.string().min(1, 'Business name is required').max(255, 'Business name must be less than 255 characters'),
    address: addressSchema,
    external_id: z.string().optional(),

});
