import React, { useState } from 'react';
import LockIcon from '@icons/lock.svg';
import CardIcon from '@icons/card-icon.svg';
import VisaIcon from '@icons/visa-icon.svg';
import MastercardIcon from '@icons/mastercard-icon.svg';
import AmexIcon from '@icons/amex-icon.svg';
import BuildingBalanceIcon from '@icons/building-balance-icon.svg';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import cx from 'classnames';
import { Button, Modal, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { SIGN_UP_SUPPLIER_ID_KEY } from '@ar/pages/SignUpPage/CustomerSignUpPage';
import { getGpoId } from '@v2/utils/GPOUtils';
import { RadioButton } from '@/components/shared';
import { useGetSupplier } from '@/ar/hooks/queries/SupplierQueries.hook';
import { drawerInsideClassName } from '@/ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';
import './CreatePaymentMethodModal.scss';

export interface PaymentMethodModalProps {
    isOpen?: boolean,
    handleClose?: () => void,
    openCardModal?: () => void,
    openACHModal?: () => void,
}

const EmptyState : React.FC = () => <>
    <Typography className="text-gray-600">
        Payment methods are not yet enabled for the current supplier. Please contact&nbsp;
        <a target="_blank"
            rel="noreferrer"
            className="text-plum-300 hover:text-plum-400"
            href="mailto:support@notch.financial?subject=Customer%20payment%20methods%20not%20enabled">customer support</a>
        &nbsp;to enable them.
    </Typography>
</>;

const ACHNewBanner : React.FC = () => <>
    <div className="flex flex-row new-banner">
        <Typography variant="BASE" className="font-bold text-purple-900">
                        NEW! &nbsp;
        </Typography>
        <Typography variant="BASE" className="font-noraml text-purple-900">
                        You can now set up bank payment on behalf of your customers.
        </Typography>
    </div>
</>;

const SecurityFooter : React.FC = () => <>
    <div className="flex flex-row items-center mt-2">
        <LockIcon className="h-4 w-4 mr-1" />
        <Typography variant="BASE" className="text-gray-600 mb-0">Securely store your payment information</Typography>
    </div>
</>;

export const CreateAdyenPaymentMethodModal : React.FC<PaymentMethodModalProps> = ({ isOpen, handleClose, openCardModal, openACHModal } : PaymentMethodModalProps) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const [isCreatePaymentMethodModalOpen, setCreatePaymentMethodModalOpen] = React.useState<boolean>(false);
    const gpoId = getGpoId(supplierLoginData);
    const { data } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });
    const [selected, setSelected] = useState<string>('');

    // id is stored in local storage when the customer signs up if not then use the first supplier id from the supplier mappings list (first check if we are a supplier and a customer)
    const supplierID = supplierLoginData.supplier_id ?? window.localStorage.getItem(SIGN_UP_SUPPLIER_ID_KEY) ?? data?.suppliers[0]?.id;
    useGetSupplier({
        supplierID,
        customerId: supplierLoginData?.customer_id,
        gpoId
    });

    const onClose = () => {
        setCreatePaymentMethodModalOpen(false);
        handleClose();
    };

    React.useEffect(() => {
        if (isOpen) {
            setCreatePaymentMethodModalOpen(true);
        }
    }, [isOpen]);

    const isUSSupplier = supplier?.billing_address?.country?.toLowerCase() === 'us';
    const hasNoPaymentMethodsEnabled = !supplier?.allow_customer_pay_with_bank_debits && !supplier?.allow_customer_pay_with_cards;
    const showCreditCardButton = !!supplier?.allow_customer_pay_with_cards;
    const showACHButton = !!supplier?.allow_customer_pay_with_bank_debits && isUSSupplier;

    if (!isOpen) {
        return null;
    }

    return <Modal
        className={drawerInsideClassName}
        isOpen={isCreatePaymentMethodModalOpen}
        close={onClose}
        onClose={onClose}
        titleSeparatorDesktop={true}
        desktopModalWidth="lg:w-[560px]"
        title={<div className="flex flex-col gap-2">
            <Typography variant="2XL" className="font-bold m-0">Add a payment method</Typography>
        </div>}>
        <div className="flex flex-col gap-4 px-8 my-8">
            {showACHButton && <ACHNewBanner />}
            <div className="flex flex-col gap-2 items-center ">
                {showCreditCardButton && <Button
                    className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
                    variant="TERTIARY_OUTLINED"
                    size="MEDIUM"
                    onClick={() => setSelected('credit-card')}>
                    <RadioButton id="add-credit-card-payment-method"
                        name="credit-card"
                        label=""
                        isChecked={selected === 'credit-card'}
                        onChecked={() => setSelected('credit-card')}/>
                    <div className="flex gap-3 items-center">
                        <CardIcon className="h-4 w-4"/> Credit Card
                        <VisaIcon className="h-5 w-5"/>
                        <MastercardIcon className="h-5 w-5"/>
                        <AmexIcon className="h-5 w-5"/>
                    </div>
                </Button>}
                {showACHButton && <Button
                    className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
                    variant="TERTIARY_OUTLINED"
                    size="MEDIUM"
                    onClick={() => setSelected('ach')}>
                    <RadioButton id="add-credit-card-payment-method"
                        name="ach"
                        label=""
                        isChecked={selected === 'ach'}
                        onChecked={() => setSelected('ach')}/>
                    <div className="flex gap-3">
                        <BuildingBalanceIcon
                            className="h-4 w-4"/> Set up US Bank Account
                    </div>
                </Button>}
                {hasNoPaymentMethodsEnabled
                    && <EmptyState />}
                {!hasNoPaymentMethodsEnabled && <SecurityFooter />}
            </div>
        </div>
        {!hasNoPaymentMethodsEnabled && <div className="w-full h-px bg-gray-200" />}
        {!hasNoPaymentMethodsEnabled && <div className="my-4 mx-4 flex justify-end">
            <Button
                disabled={selected === ''}
                variant={'SECONDARY'}
                size="MEDIUM"
                minWidth="w-auto"
                type="submit"
                className={cx('flex items-center')}
                onClick={() => {
                    if (selected === 'credit-card') {
                        openCardModal();
                    } else {
                        openACHModal();
                    }
                }}>
                Next<ArrowRightIcon className="h-5 w-5 ml-1"/>
            </Button>
        </div>}
    </Modal>;
};
