import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Button, Input, mergeClassNames, Modal, Select, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@icons/check-icon.svg';
import { v4 } from 'uuid';
import BigNumber from 'bignumber.js';
import RefreshIcon from '@icons/refresh-icon.svg';
import { ApBillItem } from '@/ar/network/AccountsReceivable.network';
import { CurrencyInput } from '../shared/InputCurrency';
import { centsToDollars, dollarsToCents } from '@/utils/Formatters';
import { ApBillModals, useApBillStore } from '@/ar/stores/ApBillStore';
import { calculateTaxAmount, calculateUnitPrice, itemCodeGenerator } from './AppBillItemFormConstants';
// import { queryClient } from '@/containers/app/Root';

export interface ApBillItemFormModalProps {
    onClose?: () => void,
    onItemSubmit: (item: ApBillItem, isNewItem: boolean) => void,
}

export enum ProductUnitOfMeasure {
    Case = 'CA',
    Each = 'EA',
    Kg = 'Kg',
    Lb = 'lb'
}

export const lineItemInventoryValidationSchema = z.object({
    item_code: z.string().nonempty('Product code is required'),
    unit_name: z.string().default(ProductUnitOfMeasure.Case),
    description: z.string().nonempty('Product is required'),
    quantity: z.number({ required_error: 'Quantity is required', }),
    price: z.number({ required_error: 'Price is required' }),
    tax_amount: z.number().optional().default(0),
    total: z.number().default(0),
    tax_percentage: z.number().optional().default(0),
    discount: z.number().optional().default(0),
    unit_price: z.number().optional().default(0),
});

type LineItemForm = z.infer<typeof lineItemInventoryValidationSchema>;

const DEFAULT_ITEM: LineItemForm = {
    description: '',
    quantity: 0,
    price: 0,
    tax_amount: 0,
    total: 0,
    tax_percentage: 0,
    discount: 0,
    item_code: '',
    unit_name: ProductUnitOfMeasure.Case,
    unit_price: 0,
};

export const ApBillItemFormModal: React.FC<ApBillItemFormModalProps> = ({ onClose, onItemSubmit }) => {
    const { modals, closeModal } = useApBillStore();
    const item = modals?.selectedItem;
    const isNewItem = !item;

    const { handleSubmit, control, reset, setValue, watch, setFocus } = useForm<LineItemForm>({
        resolver: zodResolver(lineItemInventoryValidationSchema),
        defaultValues: { ...item },
        mode: 'onBlur',
    });
    const { errors } = useFormState({
        control,
    });

    const handleOnClose = () => {
        reset(DEFAULT_ITEM);
        onClose?.();
        closeModal(ApBillModals.LineItem);
    };

    const handleOnSuccess = () => {
        handleOnClose();
        onClose?.();
        toast.show({
            message: `Item ${isNewItem ? 'added' : 'updated'}`,
            icon: <CheckIcon />,
            showClose: false,
        });
        // queryClient.invalidateQueries([FETCH_TRANSACTION_ITEMS_QUERY_KEY]);
    };

    const quantity = watch('quantity');
    const price = watch('price');
    const taxPercentage = watch('tax_percentage');
    const discount = watch('discount');
    const description = watch('description');
    const { formattedUnitPrice, unitPrice } = calculateUnitPrice(price, taxPercentage, discount);

    useEffect(() => {
        const total = new BigNumber(quantity ?? 0).times(unitPrice ?? 0).toNumber();
        setValue('total', total);
    }, [quantity, price, taxPercentage, unitPrice]);

    useEffect(() => {
        if (item) {
            reset({ ...item,
                price: centsToDollars(item.price),
                discount: centsToDollars(item.discount),
                tax_amount: centsToDollars(item.tax_amount), });
        } else {
            reset(DEFAULT_ITEM);
        }
        setTimeout(() => {
            setFocus('description');
        });
    }, [item, setValue]);

    const onSubmit = (data: LineItemForm) => {
        onItemSubmit({
            id: isNewItem ? v4() : item?.id,
            price: dollarsToCents(data.price),
            quantity: data.quantity,
            tax_amount: dollarsToCents(calculateTaxAmount(unitPrice, data.tax_percentage, data.quantity).taxAmount ?? 0),
            total: dollarsToCents(data.total),
            discount: dollarsToCents(data.discount),
            description: data.description,
            item_code: data.item_code,
            tax_percentage: data.tax_percentage,
            unit_name: data.unit_name,

        }, isNewItem);
        handleOnSuccess();
    };

    if (!modals[ApBillModals.LineItem]) return null;

    return <Modal isOpen={modals[ApBillModals.LineItem]}
        onClose={handleOnClose}
        title={
            <>
                <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">
                    {isNewItem ? 'Create' : 'Edit'} Item
                </Typography>
                {!isNewItem && <Typography className="m-0 pr-10 mb-1 text-gray-600" variant="BASE">
                    {item.description}
                </Typography>}
            </>

        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[600px]" ><form onSubmit={handleSubmit(onSubmit)}
            className=" pb-0 flex flex-col gap-6 ">
            <div className="px-5 pt-5 flex flex-col gap-6">
                <div className="flex items-start gap-4 flex-nowrap">
                    <div className="w-full flex gap-1 items-end">
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => <Input
                                label="Description"
                                variant="SMALL"
                                isInvalid={!!errors.description}
                                invalidMessage={errors.description?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                        {!!description
                            && <Button
                                variant="ICON"
                                size="NO_BACKGROUND"
                                className="mt-auto"
                                as={'div'}
                                onClick={(): void => {
                                    const itemCode = itemCodeGenerator(description, watch('unit_name'));
                                    setValue('item_code', itemCode);
                                }}>
                                <RefreshIcon className={mergeClassNames('w-4 h-4', { 'animate-spin': false })}/>
                            </Button>
                        }
                    </div>

                    <Controller
                        name="item_code"
                        control={control}
                        render={({ field }) => <Input
                            label="Product code"
                            variant="SMALL"
                            isInvalid={!!errors.item_code}
                            invalidMessage={errors.item_code?.message}
                            required
                            inputProps={{
                                ...field,
                            }}/>}/>
                    <Controller
                        name="total"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            variant="SMALL"
                            required
                            allowNegative
                            isInvalid={!!errors.total}
                            invalidMessage={errors.total?.message}
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            disabled
                            label={'Total'}/>}/>

                </div>
                <div className="flex items-center gap-4">
                    <Controller
                        name="quantity"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            prefix=""
                            decimalScale={0}
                            allowNegative
                            isInvalid={!!errors.quantity}
                            invalidMessage={errors.quantity?.message}
                            required
                            variant="SMALL"
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label="Qty"/>}/>
                    <Controller
                        name="unit_name"
                        control={control}
                        render={({ field }) => <Select required
                            label="Unit of measurement"
                            variant="SMALL"
                            isInvalid={!!errors.unit_name}
                            invalidMessage={errors.unit_name?.message}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value);
                            }}

                            options={[
                                { label: 'Case', value: ProductUnitOfMeasure.Case },
                                { label: 'Each', value: ProductUnitOfMeasure.Each },
                                { label: 'Kilogram', value: ProductUnitOfMeasure.Kg },
                                { label: 'Pound', value: ProductUnitOfMeasure.Lb },
                            ]} />}/>
                </div>
                <div className="flex items-center gap-4 w-full">

                    <Controller
                        name="price"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            required
                            isInvalid={!!errors.price}
                            invalidMessage={errors.price?.message}
                            variant="SMALL"
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Price'}/>}/>

                    <Controller
                        name="tax_percentage"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            suffix={'%'}
                            prefix=""
                            variant="SMALL"
                            required
                            isInvalid={!!errors.tax_percentage}
                            invalidMessage={errors.tax_percentage?.message}
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Tax rate included'}/>}/>
                    <Controller
                        name="discount"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            variant="SMALL"
                            required
                            isInvalid={!!errors.discount}
                            invalidMessage={errors.discount?.message}
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Discount'}/>}/>
                    <CurrencyInput
                        variant="SMALL"
                        required
                        allowNegative
                        value={formattedUnitPrice}
                        disabled
                        label={'Unit price'}/>

                </div>
            </div>

            <Separator className="my-4"/>

            <div className="flex items-center gap-4 ml-auto w-full px-5">
                <Button
                    onClick={handleOnClose}
                    variant="TERTIARY_FILLED"
                    size="SMALL"
                    as="div"
                    className="ml-auto"
                    minWidth="w-auto">
                    Cancel
                </Button>
                <Button
                    variant={'SECONDARY'}
                    size="SMALL"
                    minWidth="w-auto"
                    type="submit">
                    {isNewItem ? 'Create' : 'Update'}
                </Button>

            </div>
        </form>
    </Modal>;
};
