import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { Button, Input, InputProps, mergeClassNames, Typography } from '@notch-ordering/ui-components';
import { parseISO } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import { formatDate, formatIsoStringToUtcDate } from '@/utils/DateUtils';
import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerInputProps {
    value: InputProps['value'],
    label: InputProps['label'],
    format?: string,
    onChange?: (value: string) => void,
    id?: InputProps['id'],
    onBlur?: InputProps['onBlur'],
    variant?: InputProps['variant'],
    required?: InputProps['required'],
    inputProps?: Partial<InputProps['inputProps']>,
    inputFieldProps?: Partial<InputProps>,
    datePickerClassName?: string,
}

export const DatePickerInput : React.FC<DatePickerInputProps> = ({ value, label, onChange, id, onBlur, variant = 'ADVANCED_ALTERNATIVE', required, inputProps, inputFieldProps, datePickerClassName }) => {
    const [isDatePickerHidden, setIsDatePickerHidden] = React.useState(true);
    const refInput = React.useRef<HTMLInputElement>(null);
    const refDatePicker = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const handler = (e: KeyboardEvent): void => {
            if (e.key === 'Escape') {
                setIsDatePickerHidden(true);
            }
        };
        document.addEventListener('keydown', handler);
        // event to detect if the user clicks outside the date picker or input
        const clickHandler = (e: MouseEvent): void => {
            const target = e.target as HTMLElement;
            if (target === refInput.current || target === refDatePicker.current) {
                return;
            }
            setIsDatePickerHidden(true);
        };
        // add event to every element including inputs
        document.addEventListener('click', clickHandler);

        return (): void => {
            document.removeEventListener('keydown', handler);
            document.removeEventListener('click', clickHandler);
        };
    }, []);

    return <><Input variant={variant}
        label={label}
        id={id}
        inputProps={{
            onClick: (): void => {
                setIsDatePickerHidden(false);
            },
            ref: refInput,
            ...inputProps
        }}
        onFocus={(): void => {
            setIsDatePickerHidden(false);
        }}
        onChange={(e): void => {
            onChange?.(e.target.value);
        }}
        onBlur={onBlur}
        value={value}
        required={required}
        inputClassName={'pr-10'}
        inputIcon={<ChevronDownIcon className="h-4 w-4 text-gray-600" />}
        {...inputFieldProps}/>
    <div ref={refDatePicker} className={mergeClassNames('drop-shadow-xl absolute top-20 right-0 z-10', { hidden: isDatePickerHidden }, datePickerClassName)}>
        <DatePicker
            renderCustomHeader={({
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                monthDate,

            }):JSX.Element => (
                <div className="flex items-center justify-between">
                    <Typography variant="LG-1" weight="font-medium" className="text-gray-700 pl-3.5">{formatIsoStringToUtcDate(monthDate.toISOString(), 'MMMM, YYY')}</Typography>
                    <div className="flex gap-3 ml-auto mr-2">
                        <Button className="p-0"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                            variant="ICON"
                            size="NO_BACKGROUND">
                            <ChevronDownIcon className="w-4 h-4 text-gray-600 rotate-90"/>
                        </Button>
                        <Button className="p-0"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                            variant="ICON"
                            size="NO_BACKGROUND">
                            <ChevronDownIcon className="w-4 h-4 text-gray-600 -rotate-90"/>
                        </Button>

                    </div>

                </div>
            )}
            selected={value ? parseISO(value as string) : null}
            selectsRange={false}
            onChange={(date: Date) => {
                onChange?.(formatDate(date));
                refInput.current?.focus();
                refInput.current?.blur();
                setIsDatePickerHidden(true);
            }}
            inline/>
    </div>
    </>;
};
