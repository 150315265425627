import { Drawer, Tabs } from '@notch-ordering/ui-components';
import React from 'react';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { drawerInsideClassName } from '../../CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';
import { CustomerDetailsTabs } from '../../CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup.constants';
import { CreateCustomerForm } from './ManageCustomerForm';
import { GetCustomerResponse } from '@/ar/network/AccountsReceivable.network';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

export type ManageCustomerDrawerProps = {
    onMutate: (customer:GetCustomerResponse | null) => void,
};

export const ManageCustomerDrawer: React.FC<ManageCustomerDrawerProps> = ({ onMutate }): JSX.Element => {
    const drawerInsideSelectors = [`.${drawerInsideClassName}`, '.pac-container'];

    const { supplier } = useSupplierStore();

    const { closeModal, modals } = useDraftInvoiceStore();

    const handleClose = (): void => {
        closeModal(DraftInvoiceModals.ManageCustomerDrawer);
    };
    if (!supplier.allow_edit_customer) {
        return null;
    }

    return (
        <div className="min-h-screen w-full fixed right-0 top-0 z-50 pointer-events-none">
            <Drawer isVisible={modals.manageCustomerModal}
                close={handleClose}
                clickOutsideToClose
                insideSelectors={drawerInsideSelectors}
                title="Manage customer"
                className="bg-white pointer-events-auto"
                headerContent={<Tabs onChange={() => {}}
                    tabs={['Contact']}
                    value={CustomerDetailsTabs.Contact}
                    className="pt-6 px-10" />} >
                <CreateCustomerForm onMutate={onMutate} />
            </Drawer>
        </div>
    );
};
