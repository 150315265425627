import { Banner, Button, EBannerType, Input, Modal, Separator, Typography, toast } from '@notch-ordering/ui-components';
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import ExampleCheck from '@ar/assets/images/example-check.png';
import CheckIcon from '@icons/check-icon.svg';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import { FETCH_BALANCES_QUERY_KEY } from '@ar/hooks/queries/BalanceQueries.hook';
import { FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { drawerInsideClassName } from '@/ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';
import { attachAdyenPaymentMethod } from '@/ar/network/AccountsReceivable.network';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { encryptWithPem } from '@/utils/test-encrypt';

export interface CreateBankAccountProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    supplierID: string,
}

type BankAccountDetails = {
    ownerName: string,
    routingNumber: string,
    accountNumber: string,
    confirmAccountNumber: string,
};

const NUM_FIELDS = 4;

/**
 * ACH routing numbers have 9 digits and the last digit is a checksum.
 * The following condition must hold:
 * (3(d1 + d4 + d7) + 7(d2 + d5 + d8) + (d3 + d6 + d9)) mod 10 = 0
 * https://stripe.com/en-ca/resources/more/ach-routing-numbers-explained
 * https://en.wikipedia.org/wiki/ABA_routing_transit_number
 */
const isValidRoutingNumber = (routingNumber: string): boolean => routingNumber.length === 9
    && routingNumber.split('').map(Number).reduce((sum, digit, index) => {
        const weights = [3, 7, 1];
        const weightIndex = index % 3;
        return sum + digit * weights[weightIndex];
    }, 0) % 10 === 0;

export const validationSchema = z.object({
    ownerName: z.string().nonempty('Name of account holder is required').min(2, 'Must be full name'),
    routingNumber: z.string().nonempty('Routing number is required').length(9, 'Must be 9 digits'),
    accountNumber: z.string().nonempty('Account number is required').min(10, 'Must be 10-12 digits').max(12, 'Must be 10-12 digits'),
    confirmAccountNumber: z.string().nonempty('Confirm account number is required')
}).refine((data) => data.confirmAccountNumber === data.accountNumber, {
    message: 'Account numbers must match',
    path: ['confirmAccountNumber'],
}).refine((data) => isValidRoutingNumber(data.routingNumber), {
    message: 'Routing number is invalid. Please verify and re-enter',
    path: ['routingNumber'],
});

type AttachPaymentMethodError = { error: string };

export const CreateBankAccount = ({ isOpen, handleClose, customerID, supplierID }: CreateBankAccountProps): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const supplierIDToUse = supplierID ?? supplierLoginData?.supplier_id;
    const queryClient = useQueryClient();
    const attachPaymentMethodMutation = useMutation(attachAdyenPaymentMethod);

    const [hasFormSubmitted, setHasFormSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

    const emptyBankAccount = {
        ownerName: '',
        routingNumber: '',
        accountNumber: '',
        confirmAccountNumber: ''
    };

    const { handleSubmit, control, reset, watch } = useForm<BankAccountDetails>({
        resolver: zodResolver(validationSchema),
        defaultValues: emptyBankAccount,
        mode: 'onBlur',
    });

    const { errors, touchedFields } = useFormState({
        control,
    });

    // Watch all fields to trigger re-render on change
    const watchedFields = watch();

    const resetForm = (): void => {
        reset();
        setIsSubmitDisabled(true);
    };

    const handleOnClose = ():void => {
        setHasFormSubmitted(false);
        resetForm();
        handleClose();
    };

    const encrypt = async (newFormBankAccount: BankAccountDetails): Promise<{ encryptedOwnerName: string, encryptedRoutingNumber: string, encryptedAccountNumber: string }> => {
        const { ownerName, routingNumber, accountNumber } = newFormBankAccount;
        const encryptedOwnerName = encryptWithPem(ownerName);
        const encryptedRoutingNumber = encryptWithPem(routingNumber);
        const encryptedAccountNumber = encryptWithPem(accountNumber);
        return { encryptedOwnerName, encryptedRoutingNumber, encryptedAccountNumber };
    };

    const onSubmit = async (newFormBankAccount: BankAccountDetails): Promise<void> => {
        const { encryptedOwnerName, encryptedRoutingNumber, encryptedAccountNumber } = await encrypt(newFormBankAccount);
        attachPaymentMethodMutation.mutate({
            body: {
                bankData: {
                    type: 'us_bank_account',
                    accountRole: 'checking',
                    ownerName: encryptedOwnerName,
                    routingNumber: encryptedRoutingNumber,
                    accountNumber: encryptedAccountNumber
                },
                customerId: customerID,
                supplierId: supplierIDToUse,
            }
        }, {
            onSuccess: async () => {
                setHasFormSubmitted(true);
                toast.show({
                    message: 'Bank account added successfully',
                });
                await queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                await queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
                await queryClient.invalidateQueries([FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                handleOnClose();
            },
            onError: (err: AxiosError<AttachPaymentMethodError>) => {
                setHasFormSubmitted(true);
                const errorData = err?.response?.data;
                setErrorMessage(errorData?.error);
                resetForm();
            }
        });
    };

    useEffect(() => {
        const hasErrors = Object.keys(errors).some((key) => errors[key]);
        const allFieldsTouched = Object.keys(touchedFields).length === NUM_FIELDS && Object.values(touchedFields).every((value) => value);
        setIsSubmitDisabled(hasErrors || !allFieldsTouched);
    }, [errors, touchedFields, watchedFields]);

    return <Modal isOpen={isOpen}
        title={
            <>
                <div className="flex flex-row gap-2">
                    <Typography className="m-0 pr-10" weight="font-medium" variant="LG-2" desktopSize="lg:text-5">Add a US bank account</Typography>
                </div>
            </>
        }
        className={drawerInsideClassName}
        headerPadding="mt-3 mb-4 mx-3"
        titleSeparatorDesktop={true}
        closeButtonClassName="mt-3 mr-3"
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[563px]">

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-6 min-w-0">
                <div className="flex-col flex gap-4">
                    {hasFormSubmitted && errorMessage
                        && <Banner alertType={EBannerType.ERROR}
                            className="px-2 rounded-none"
                            title={<Typography as="div" className="text-red-500 text-2">
                                Unable to add payment method. Please try again.
                            </Typography>}
                            body={<Typography as="div" className="text-gray-600 text-1">
                                {errorMessage}
                            </Typography>} />}
                    <Typography weight="font-regular" variant="BASE" desktopSize="lg:text-3.5" className="m-0">Enter your customer’s bank account information.</Typography>
                    <Controller
                        name="ownerName"
                        control={control}
                        render={({ field }) => <Input
                            name="ownerName"
                            id="ownerName"
                            label="Name of account holder"
                            type="TEXT"
                            inputProps={{
                                placeholder: 'Must match name on account',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.ownerName}
                            invalidMessage={errors.ownerName?.message}
                            labelIcon={touchedFields.ownerName && !errors.ownerName ? <CheckIcon data-testid="owner-name-check" className="h-4 w-4 text-green-400" /> : null}/>}/>
                    <Controller
                        name="routingNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="routingNumber"
                            id="routingNumber"
                            label="Routing number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '9 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.routingNumber}
                            invalidMessage={errors.routingNumber?.message}
                            labelIcon={touchedFields.routingNumber && !errors.routingNumber ? <CheckIcon data-testid="routing-number-check" className="h-4 w-4 text-green-400" /> : null}/>}/>
                    <Controller
                        name="accountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="accountNumber"
                            id="accountNumber"
                            label="Account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '10-12 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.accountNumber}
                            invalidMessage={errors.accountNumber?.message}
                            labelIcon={touchedFields.accountNumber && !errors.accountNumber ? <CheckIcon data-testid="account-number-check" className="h-4 w-4 text-green-400" /> : null}/>}/>
                    <Controller
                        name="confirmAccountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="confirmAccountNumber"
                            id="confirmAccountNumber"
                            label="Confirm account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '10-12 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.confirmAccountNumber}
                            invalidMessage={errors.confirmAccountNumber?.message}
                            labelIcon={touchedFields.confirmAccountNumber && !errors.confirmAccountNumber ? <CheckIcon data-testid="confirm-account-number-check" className="h-4 w-4 text-green-400" /> : null}/>}/>
                    <img
                        className="example-check-image"
                        src={ExampleCheck}/>
                </div>
            </div>

            <Separator/>
            <div className="pt-5 px-5 flex justify-end gap-3">
                <Button variant="TERTIARY_FILLED"
                    as={'span'}
                    className="cursor-pointer"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
                        Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    size="SMALL"
                    disabled={isSubmitDisabled}
                    loading={attachPaymentMethodMutation.isLoading}>
                    <div className="flex gap-1">
                        <Typography as="span" weight="font-medium">
                            Save & next
                        </Typography>
                        <ArrowRightIcon className="w-4 h-4 mt-0.5"/>
                    </div>
                </Button>
            </div>
        </form>
    </Modal>;
};
