import React, { useContext } from 'react';
import { Button, Loading, Typography } from '@notch-ordering/ui-components';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import BackIcon from '@icons/back-icon.svg';
import SettingIcon from '@icons/setting-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import cx from 'classnames';
import { CustomerInvoices } from '@ar/components/CustomerDetails/CustomerInvoices/CustomerInvoices';
import { CustomerDetailsTabs } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup.constants';
import { useRefetchIncompletePaymentMethods } from '@ar/hooks/useRefetchPaymentMethods';
import { LoadingPaymentMethodBanner } from '@ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

/**
 * The AR Customer page
 *
 * @returns JSX Element
 */
export const CustomerDetailsPage = (): JSX.Element => {
    useDocumentTitle('Customer Details - Notch');
    const navigate = useNavigate();
    const [isAdvancedOpen, setIsAdvancedOpen] = React.useState<boolean>(false);
    const { supplierLoginData, supplier } = useSupplierStore();
    const flags = useFlags();
    const {
        customer,
        isCustomerLoading,
        customerBalanceData,
        isLoadingCustomerBalanceData,
        paymentMethods,
        isLoadingPaymentMethods,
        setIsCustomerPopupOpen,
        setCustomerPopupTab,
    } = useContext(CustomerDetailsContext);

    const handleClickOpenPayment = (): void => {
        setIsCustomerPopupOpen(true);
        setCustomerPopupTab(CustomerDetailsTabs.Payments);
    };

    const isLoading = isCustomerLoading || isLoadingCustomerBalanceData || isLoadingPaymentMethods;

    const methods = paymentMethods?.payment_methods?.map((paymentMethod, index, selfArray) => <>
        <PaymentMethodBadge
            key={paymentMethod.id}
            className="mb-0"
            brand={paymentMethod.brand}
            last4={paymentMethod.last4}
            autoCollection = {customer?.auto_collection}/>
        {index !== selfArray.length - 1 && <div className="w-full h-px bg-gray-200" />}
    </>);

    const hasIncompletePaymentMethods = useRefetchIncompletePaymentMethods({ paymentMethods: paymentMethods?.payment_methods });
    const showCustomerInternalName = supplier.enable_accounting_internal_customer_name && customer?.platform_internal_name;

    return (
        <div className="-mx-10 -my-10 w-[calc(100%+80px)]">
            {isLoading && <Loading isDark className="mt-20" />}
            {!isLoading && <div className="flex h-full">
                <div className="w-full h-full">
                    <div className="px-10 pt-9 pb-8">
                        <Button className="flex items-center -ml-3.5 mb-6 text-gray-600"
                            size="SMALL"
                            variant="SUBDUED_ICON"
                            onClick={():void => {
                                const { history } = window;
                                if (history.length > 1) {
                                    history.back();
                                } else {
                                    navigate(`/${ARRoutePaths.AR_CUSTOMERS}`);
                                }
                            }}>
                            <BackIcon className="w-4 h-4 mr-3" />
                            Back
                        </Button>

                        <Typography
                            className="mt-7.5"
                            as="p"
                            font="font-title"
                            variant="3XL"
                            weight="font-medium">
                            {customer?.name}
                        </Typography>
                        {showCustomerInternalName && (<Typography className="text-gray-600 mt-1" as="div">
                            {customer?.platform_internal_name}
                        </Typography>)}
                    </div>
                    <div className="w-full h-px bg-gray-200" />
                    <div className="border-r border-gray-200">
                        <div className="px-10 py-8">
                            <Typography variant="LG-2" className="mb-6" weight="font-semibold">
                                Balances
                            </Typography>
                            <div className="flex">
                                <div className="mr-[70px]">
                                    <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-1">Open</Typography>
                                    <Typography variant="LG-2" weight="font-medium">${customerBalanceData?.customers[0].open}</Typography>
                                </div>
                                <div className="mr-[70px]">
                                    <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-1">Overdue</Typography>
                                    <Typography variant="LG-2" weight="font-medium">${customerBalanceData?.customers[0].overdue}</Typography>
                                </div>
                                <div>
                                    <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-1">Paid</Typography>
                                    <Typography variant="LG-2" weight="font-medium">${customerBalanceData?.customers[0].paid}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-px bg-gray-200" />
                        <div className="px-10 py-10 overflow-scroll">
                            <Typography variant="LG-2" className="mb-6" weight="font-semibold">
                                Invoices
                            </Typography>
                            <CustomerInvoices />
                        </div>
                    </div>
                </div>
                <div className="max-w-[312px] w-full">
                    <div className="w-full">
                        {!supplierLoginData.gpo && <Button className="flex items-center mt-[30px] ml-auto mr-5 mb-[102px]"
                            size="SMALL"
                            onClick={():void => setIsCustomerPopupOpen(true)}>
                            Edit Details
                        </Button>}
                        <div className="w-full h-px bg-gray-200" />
                    </div>
                    <div className="w-full p-6">
                        <div className="pb-6">
                            <Typography variant="LG-2" className="mb-6" weight="font-semibold">
                                Basic details
                            </Typography>
                            <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-2">
                                Contact
                            </Typography>
                            <Typography variant="LG-1" className="mb-1">
                                {customer?.email}
                            </Typography>
                            <Typography variant="LG-1">
                                {customer?.phone}
                            </Typography>
                        </div>
                        <div className="w-full h-px bg-gray-200 mb-6 " />
                        <div className="pb-6">
                            <Typography variant="LG-2" className="mb-6" weight="font-semibold">
                                Credit card surcharge
                            </Typography>
                            {flags.paymentSurcharges && customer.is_surcharge_enabled
                                ? <Typography variant="LG-1" weight="font-medium" className="text-green-400 mb-2">
                                On</Typography>
                                : <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-2">
                                Off
                                </Typography>}
                        </div>
                        <div className="w-full h-px bg-gray-200" />
                        <div className="pt-8 pb-4">
                            <div className="mb-6 flex justify-between items-center">
                                <Typography variant="LG-2" weight="font-semibold">
                                    Payment
                                </Typography>
                                {!supplierLoginData.gpo && <SettingIcon className="w-4 h-4 cursor-pointer"
                                    onClick={handleClickOpenPayment}/>}
                            </div>
                            <div className="flex flex-col gap-4">
                                {methods}
                                {hasIncompletePaymentMethods && <LoadingPaymentMethodBanner className="p-0" />}
                            </div>
                        </div>
                        <div className="w-full h-px bg-gray-200" />
                        <div className="py-8">
                            <div className="flex justify-between items-center cursor-pointer"
                                onClick={():void => setIsAdvancedOpen(!isAdvancedOpen)}>
                                <Typography variant="LG-2" weight="font-semibold">
                                    Advanced
                                </Typography>
                                <ChevronDownIcon
                                    className={cx('w-4 h-4 text-gray-600 origin-center', isAdvancedOpen && 'rotate-180')} />
                            </div>
                            {isAdvancedOpen && <div className="mt-6 transition-opacity">
                                <Typography variant="LG-1" weight="font-medium" className="text-gray-600 mb-2">
                                    Customer ID
                                </Typography>
                                <Typography variant="LG-1" className="mb-1">
                                    {customer?.external_id}
                                </Typography>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};
