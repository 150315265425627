import { zodResolver } from '@hookform/resolvers/zod';
import TrashIcon from '@icons/trash-icon.svg';
import { Button, Input, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { z } from 'zod';
import { updateCustomer } from '@/api/DataAPI';
import { FETCH_CUSTOMERS_QUERY_KEY } from '@/ar/hooks/queries/CustomerQueries.hook';
import { createCustomer, CreateCustomerData, getCustomer, GetCustomerResponse } from '@/ar/network/AccountsReceivable.network';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { InputAddressAutocomplete } from '@/components/shared/InputAddressAutocomplete';
import { queryClient } from '@/containers/app/Root';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { extractAddressFromPlace } from '@/utils/AddressUtils';

const emailSchema = z.object({
    email: z.string().email({ message: 'Please provide a valid email address.' }).optional(),
});

const additionalEmailSchemaValidation = z.array(emailSchema).optional().nullish();

export const addressSchema = z.object({
    line1: z.string().optional(),
    line2: z.string().optional(),
    city: z.string().optional(),
    postal_code: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
});

export const customerFormValidationSchema = z.object({
    terms: z.coerce.number({
        invalid_type_error: 'Payment terms must be a number.',
    }).min(0).default(0),
    ccEmails: additionalEmailSchemaValidation,
    name: z.string({
        required_error: 'Business name is required.',
    }).nonempty({ message: 'Business name is required.' }),
    address: addressSchema,
    external_id: z.string().optional(),
    email: z.string().email({ message: 'Please provide a valid email address.' }).optional(),

});

export type CustomerContactFormState = z.infer<typeof customerFormValidationSchema>;

export type CreateCustomerFormProps = {
    onMutate: (customer:GetCustomerResponse | null) => void,
};

export const CreateCustomerForm : React.FC<CreateCustomerFormProps> = ({ onMutate }) :JSX.Element => {
    useDocumentTitle('Customer General - Notch');

    const { supplier, supplierLoginData } = useSupplierStore();
    const { closeModal } = useDraftInvoiceStore();
    const allowEditCustomer = supplier?.allow_edit_customer ?? false;

    const updateCustomerMutation = useMutation(
        updateCustomer,
    );

    const createCustomerMutation = useMutation(createCustomer);
    // const { isLoading } = updateCustomerMutation;
    const isLoading = createCustomerMutation.isLoading || updateCustomerMutation.isLoading;

    const { handleSubmit, control, reset, setValue } = useForm<CustomerContactFormState>({
        resolver: zodResolver(customerFormValidationSchema),
        mode: 'onSubmit',
    });

    const { errors, isSubmitted } = useFormState({
        control,
    });

    const resetCustomer = (): void => {
        reset();
    };

    const { fields, remove } = useFieldArray<CustomerContactFormState>({
        control,
        name: 'ccEmails',
    });
    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        const address = extractAddressFromPlace(place);
        setValue('address.line1', address.line1);
        setValue('address.line2', address.line2);
        setValue('address.city', address.city);
        setValue('address.state', address.state);
        setValue('address.postal_code', address.postal_code);
        setValue('address.country', address.country);
    };

    const onSubmit = (data: CustomerContactFormState) => {
        const { email, name, address, terms, external_id: externalId } = data;

        const newCustomer: CreateCustomerData = {
            business_name: name,
            email,
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            postal_code: address.postal_code,
            state: address.state,
            country: address.country,
            terms: Number(terms),
            external_id: externalId,
            supplier_id: supplierLoginData?.supplier_id,
        };
        createCustomerMutation.mutate({
            body: newCustomer,
            supplierID: supplierLoginData?.supplier_id,
        }, {
            onSuccess: async (response) => {
                toast.show({
                    message: 'Successfully created',
                    showClose: false,
                });
                await queryClient.invalidateQueries([FETCH_CUSTOMERS_QUERY_KEY]);
                const customer = await getCustomer({
                    customerID: response.customer_id,
                    supplierID: supplierLoginData?.supplier_id
                });
                onMutate(customer);
                closeModal(DraftInvoiceModals.ManageCustomerDrawer);

                resetCustomer();
            },
            onError: (error: AxiosError) => {
                const duplicationErrorMsg = 'Unique constraint failed on the fields: (`external_id`,`supplier_id`)';
                let message = 'Error while creating customer';
                if ((error.response as { data: { error: string } }).data?.error && (error.response as { data: { error: string } }).data.error.includes(duplicationErrorMsg)) {
                    message = 'The ERP Customer ID is already in use. Please enter a different one.';
                }

                toast.show({
                    message,
                    showClose: false,
                });
            },
        });
    };

    const handleClickCancel = (): void => {
        resetCustomer();
        closeModal(DraftInvoiceModals.ManageCustomerDrawer);
        onMutate(null);
    };
    console.log(errors, 'errors');

    return (
        <form className="pt-8 grid customer-details" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-10 flex flex-col gap-3">
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <Input
                        label="Name"
                        placeholder="Business name"
                        isInvalid={isSubmitted && Boolean(errors?.name?.message)}
                        invalidMessage={isSubmitted && errors?.name?.message}
                        variant="SMALL"
                        disabled={!allowEditCustomer}
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <Input
                        label="Email"
                        isInvalid={isSubmitted && Boolean(errors?.email?.message)}
                        invalidMessage={errors?.email?.message}
                        variant="SMALL"
                        disabled={!allowEditCustomer}
                        required
                        placeholder="Enter an email address"
                        inputProps={{
                            ...field,
                        }}/>}/>

                {fields.map((field, index) => (
                    <div key={field.id}>
                        <div className="flex gap-3 items-center">
                            <Controller
                                name={ `ccEmails.${index}.email`}
                                control={control}
                                render={({ field: f }) => <Input
                                    variant="SMALL"
                                    placeholder="Enter an email address"
                                    required
                                    isInvalid={isSubmitted && Boolean(errors?.ccEmails?.[index]?.email?.message)}
                                    invalidMessage={errors?.ccEmails?.[index]?.email?.message}
                                    value={f.value}
                                    inputProps={{
                                        ...f,
                                    }}/>}/>
                            <TrashIcon className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-600"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    remove(index);
                                }}/>
                        </div>
                    </div>
                ))}
                {/* <button
                    className="appearance-none"
                    onClick={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        append({ email: '' });
                    }}>
                    <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                        <AddIcon className="text-teal-500 w-4 h-4" />
                        <span className="text-teal-500">Add another email</span>
                    </Typography>
                </button> */}

                <div className="flex items-center gap-3">
                    <Controller
                        name="terms"
                        control={control}
                        render={({ field }) => <Input
                            label={'Payment Terms'}
                            placeholder="Payment terms"
                            variant="SMALL"
                            disabled={!allowEditCustomer}
                            isInvalid={isSubmitted && Boolean(errors?.terms)}
                            invalidMessage={errors?.terms?.message}
                            required
                            name="terms"
                            inputProps={{
                                ...field,
                            }}/>

                        }/>

                    <Controller
                        name="external_id"
                        control={control}
                        render={({ field }) => <Input
                            label="ERP Customer ID"
                            placeholder="External reference number"
                            variant="SMALL"
                            required
                            disabled={!allowEditCustomer}
                            isInvalid={isSubmitted && Boolean(errors?.external_id)}
                            invalidMessage={errors?.external_id?.message}
                            inputProps={{
                                ...field,
                            }}/>}/>
                </div>

            </div>
            <Separator variant="small" className="my-6" />
            <div className="px-10 flex flex-col gap-3">
                <Typography variant="LG-2" className="mb-3">Billing address</Typography>
                <div className="flex">
                    <div className="w-3/4 mr-3">
                        <Controller
                            name="address.line1"
                            control={control}
                            render={({ field }) => (allowEditCustomer ? <InputAddressAutocomplete
                                variant="SMALL"
                                className="w-full"
                                isInvalid={!!errors?.address?.line1}
                                label="Street address"
                                value={field.value}
                                onChange={(e): void => {
                                    field.onChange(e);
                                }}
                                googleAutocompleteInputProps={{
                                    // this will disable the enter key from submitting the form while selecting an address
                                    onKeyDown: (e) :void => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    },
                                }}
                                onPlaceSelected={handlePlaceSelected}
                                // isInvalid={formErrors.address}
                                searchTypes={['address']}
                                invalidMessage={'Street address is required'}
                                required/>
                                : <Input
                                    label="Street address"
                                    variant="SMALL"
                                    disabled={!allowEditCustomer}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>)

                            }/>
                    </div>
                    <div className="w-1/4">
                        <Controller
                            name="address.line2"
                            control={control}
                            render={({ field }) => <Input
                                label="Unit"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
                <div className=" flex">
                    <div className="w-1/2 mr-3">
                        <Controller
                            name="address.city"
                            control={control}
                            render={({ field }) => <Input
                                label="City"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="w-1/2">
                        <Controller
                            name="address.state"
                            control={control}
                            render={({ field }) => <Input
                                label="Province"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
                <div className=" flex">
                    <div className="w-1/2 mr-3">
                        <Controller
                            name="address.country"
                            control={control}
                            render={({ field }) => <Input
                                label="Country"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="w-1/2">
                        <Controller
                            name="address.postal_code"
                            control={control}
                            render={({ field }) => <Input
                                label="Postal Code"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
            </div>
            <Separator variant="small" className="mt-6" />
            <div className="px-10 py-6 flex justify-between">
                <Button className="mr-3 cursor-pointer"
                    as="div"
                    onClick={handleClickCancel}
                    disabled={!allowEditCustomer}
                    variant="TERTIARY_FILLED"
                    size="MEDIUM"
                    minWidth="w-auto">
                      Cancel
                </Button>
                <Button
                    disabled={!allowEditCustomer}
                    loading={isLoading}
                    variant={'SECONDARY'}
                    size="MEDIUM"
                    minWidth="w-auto"
                    type="submit">
                      Save
                </Button>
            </div>
        </form>
    );
};
