import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreateStripePaymentMethod } from '@ar/components/shared/modals/CreateStripePaymentMethod/CreateStripePaymentMethod';
import { CreateAdyenPaymentMethodModal } from '@/ar/components/shared/modals/CreateAdyenPaymentMethod/CreatePaymentMethodModal';
import { CreateAdyenCardPaymentMethod } from '@/ar/components/shared/modals/CreateAdyenPaymentMethod/CreateAdyenCardPaymentMethod';
import { ActivePaymentMethods } from '@/utils/constants';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { CreateBankAccount } from '../CreateBankAccount/CreateBankAccount';

export interface AddNewPaymentMethodProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    subTitle?: string,
    supplierID?: string,
}

export const CreatePaymentMethod = ({
    isOpen,
    handleClose,
    customerID,
    subTitle,
    supplierID
}: AddNewPaymentMethodProps): JSX.Element => {
    const flags = useFlags<FeatureFlags>();
    const enableAddACHPaymentMethodsWithAdyen = flags?.arPaymentProvider?.us_bank_account === ActivePaymentMethods.adyen;
    const enableAddPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const showStripePaymentMethodModal = !enableAddPaymentMethodsWithAdyen && isOpen;
    const showAdyenPaymentMethodModal = enableAddPaymentMethodsWithAdyen && !enableAddACHPaymentMethodsWithAdyen && isOpen;
    const showGenericPaymentMethodModal = enableAddACHPaymentMethodsWithAdyen && isOpen;

    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [isACHModalOpen, setIsACHModalOpen] = useState(false);

    return <>
        {showStripePaymentMethodModal
            && <CreateStripePaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                supplierID={supplierID}
                subTitle={subTitle}
                customerID={customerID}/>
        }
        {showAdyenPaymentMethodModal
            && <CreateAdyenCardPaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                subTitle={subTitle}
                supplierID={supplierID}
                customerID={customerID}/>
        }
        {showGenericPaymentMethodModal // Planning for this to be the de facto payment method modal once we're fully on Adyen for cards, ACH, and EFT
            && <CreateAdyenPaymentMethodModal isOpen={isOpen}
                handleClose={handleClose}
                openCardModal={() => setIsCardModalOpen(true)}
                openACHModal={() => setIsACHModalOpen(true)}/>
        }
        {showGenericPaymentMethodModal
            && <CreateAdyenCardPaymentMethod isOpen={isCardModalOpen}
                handleClose={() => setIsCardModalOpen(false)}
                subTitle={subTitle}
                supplierID={supplierID}
                customerID={customerID}/>
        }
        {showGenericPaymentMethodModal
            && <CreateBankAccount isOpen={isACHModalOpen}
                handleClose={() => setIsACHModalOpen(false)}
                supplierID={supplierID}
                customerID={customerID}/>
        }
    </>;
};
