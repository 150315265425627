import React, { useContext } from 'react';
import { Drawer, Tabs } from '@notch-ordering/ui-components';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { CustomerGeneralTab } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralTab/CustomerGeneralTab';
import { CustomerNotificationsTab } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerNotificationTab/CustomerNotificationsTab';
import { CustomerPaymentsTab } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerPaymentsTab/CustomerPaymentsTab';
import { CustomerDetailsTabs } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup.constants';

export const drawerInsideClassName = 'drawer-body';

/**
 * The AR Customer Wrapper page
 *
 * @returns JSX Element
 */
export const CustomerGeneralPopup = (): JSX.Element => {
    const {
        customerPopupTab,
        setCustomerPopupTab,
        isCustomerPopupOpen,
        handleCloseGeneralPopup,
    } = useContext(CustomerDetailsContext);

    const handleTabChange = (value: string): void => {
        setCustomerPopupTab(value);
    };

    const drawerInsideSelectors = [`.${drawerInsideClassName}`, '.pac-container'];

    return (
        <div className="min-h-screen w-full fixed right-0 top-0 z-50 pointer-events-none">
            <Drawer isVisible={isCustomerPopupOpen}
                close={handleCloseGeneralPopup}
                clickOutsideToClose
                insideSelectors={drawerInsideSelectors}
                title="Manage customer"
                className="bg-white pointer-events-auto"
                headerContent={<Tabs onChange={handleTabChange}
                    tabs={Object.values(CustomerDetailsTabs)}
                    value={customerPopupTab}
                    className="pt-6 px-10" />} >
                {customerPopupTab === CustomerDetailsTabs.Contact && <CustomerGeneralTab />}
                {customerPopupTab === CustomerDetailsTabs.Notifications && <CustomerNotificationsTab resetButtonEnabled={true}/>}
                {customerPopupTab === CustomerDetailsTabs.Payments && <CustomerPaymentsTab />}
            </Drawer>
        </div>
    );
};
