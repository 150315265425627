import crypto from 'crypto';
import { environments } from '../../webpack/environments.js';

const pemBase64 = environments.local.PAYMENT_PROCESSOR_ENCRYPTION_PEM;

const pemKey = Buffer.from(pemBase64, 'base64').toString('utf-8');

export const encryptWithPem = (data: string): string => {
    const buffer = Buffer.from(data, 'utf-8');
    const encrypted = crypto.publicEncrypt({ key: pemKey, oaepHash: 'sha256', padding: crypto.constants.RSA_PKCS1_OAEP_PADDING }, Uint8Array.from(buffer));
    return encrypted.toString('base64');
};
